import { RootState } from 'store';

export const getScoreRangeFrom = (state: RootState) => state.matching.scoreFrom;
export const getScoreRangeTo = (state: RootState) => state.matching.scoreTo;
export const getThreshold = (state: RootState) => state.matching.threshold;
export const matchingTableRecords = (state: RootState) =>
  state.matching.matchingTableRecords;
export const goodItems = (state: RootState) => state.matching.good;
export const goodBuckets = (state: RootState) => state.matching.goodBuckets;
export const duplicateItems = (state: RootState) => state.matching.duplicates;
export const missingMothershipItems = (state: RootState) =>
  state.matching.missingMothership;
export const missingMothershipBuckets = (state: RootState) =>
  state.matching.missingMothershipBuckets;
export const affAssignmentItems = (state: RootState) =>
  state.matching.affAssignments;
export const loading = (state: RootState) => state.matching.loading;
export const loadingCompleted = (state: RootState) =>
  state.matching.loadingCompleted;
export const loadingAffAssignments = (state: RootState) =>
  state.matching.loadingAffAssignments;
export const loadingAffAssignmentsCompleted = (state: RootState) =>
  state.matching.loadingAffAssignmentsCompleted;
export const fetchedBuckets = (state: RootState) =>
  state.matching.fechedBuckets;
