import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import { Draft } from 'immer';

import { RootState } from 'store';
import { fetchBuckets, fetchAffAssignments } from 'lib/fetchBucket';
import type { IBucketData } from 'lib/fetchBucket';

export const LATEST_MATCH_VERSION = 1003;

export const INITIAL_MINIMUM_SCORE = 0;
export const INITIAL_MAXIMUM_SCORE = 100;
export const THRESHOLD = 100;
export const TOTAL_BUCKETS = 2000;

interface MatchingState {
  loading: boolean;
  loadingCompleted: boolean;

  loadingAffAssignments: boolean;
  loadingAffAssignmentsCompleted: boolean;

  scoreFrom: number;
  scoreTo: number;
  threshold: number;

  goodOrigin: any[];
  matchingTableRecords: any[];
  good: any[];
  missingMothership: any[];
  duplicates: any[];

  affAssignments: any[];

  goodBuckets: any;
  missingMothershipBuckets: any;
  fechedBuckets: number;
}

const initialState: MatchingState = {
  loading: false,
  loadingCompleted: false,

  loadingAffAssignments: false,
  loadingAffAssignmentsCompleted: false,

  scoreFrom: INITIAL_MINIMUM_SCORE,
  scoreTo: INITIAL_MAXIMUM_SCORE,
  threshold: THRESHOLD,

  goodOrigin: [],
  matchingTableRecords: [],
  good: [],
  missingMothership: [],
  duplicates: [],

  affAssignments: [],

  goodBuckets: {},
  missingMothershipBuckets: {},
  fechedBuckets: 0,
};

export const getMatchingRecords = createAsyncThunk(
  'matching/getMatchingRecords',
  async (args: { buckets: number[] }, thunkAPI): Promise<boolean> => {
    const state = thunkAPI.getState() as RootState;
    const fetchStatus = await fetchBuckets(
      state.session.token.accessToken ?? '',
      args.buckets,
      (data) => {
        thunkAPI.dispatch(updateProgress({ data }));
      },
    );

    return fetchStatus;
  },
);

export const getAffAssignments = createAsyncThunk(
  'matching/getAffAssignments',
  async (args, thunkAPI): Promise<any> => {
    const state = thunkAPI.getState() as RootState;
    return fetchAffAssignments(state.session.token.accessToken ?? '');
  },
);

type SetScoreRangePayload = {
  from: number;
  to: number;
};

type SetThresholdPayload = {
  threshold: number;
};

type UpdateProgresssPayload = {
  data: IBucketData;
};

type FetchAffAssignmentPayload = {
  bucket: number;
  items: any[];
};

export const matchingSlice = createSlice({
  name: 'matching',

  initialState,

  reducers: {
    setScoreRange: (
      state: Draft<MatchingState>,
      action: PayloadAction<SetScoreRangePayload>,
    ) => {
      state.scoreFrom = action.payload.from;
      state.scoreTo = action.payload.to;

      state.matchingTableRecords = state.goodOrigin.filter(
        (item) =>
          item.matchVersion === LATEST_MATCH_VERSION &&
          item.matches &&
          item.matches.length > 0 &&
          item.matches[0].score > state.scoreFrom &&
          item.matches[0].score < state.scoreTo,
      );
    },
    setThreshold: (
      state: Draft<MatchingState>,
      action: PayloadAction<SetThresholdPayload>,
    ) => {
      state.threshold = action.payload.threshold;

      state.good = state.goodOrigin.filter((item) => {
        if (item.matches && item.matches.length > 0) {
          return item.matches[0].score < state.threshold;
        }

        return true;
      });
      state.duplicates = state.goodOrigin.filter(
        (item) =>
          item.matches &&
          item.matches.length > 0 &&
          item.matches[0].score >= state.threshold,
      );
    },
    updateProgress: (
      state: Draft<MatchingState>,
      action: PayloadAction<UpdateProgresssPayload>,
    ) => {
      state.goodOrigin = state.goodOrigin.concat(action.payload.data.good);
      state.matchingTableRecords = state.goodOrigin.filter(
        (item) =>
          item.matches &&
          item.matches.length > 0 &&
          item.matchVersion === LATEST_MATCH_VERSION &&
          item.matches[0].score > state.scoreFrom &&
          item.matches[0].score < state.scoreTo,
      );
      state.good = state.goodOrigin.filter((item) => {
        if (item.matches && item.matches.length > 0) {
          return item.matches[0].score < state.threshold;
        }

        return true;
      });
      state.missingMothership = state.missingMothership.concat(
        action.payload.data.missingMothership,
      );
      state.duplicates = state.goodOrigin.filter(
        (item) =>
          item.matches &&
          item.matches.length > 0 &&
          item.matches[0].score >= state.threshold,
      );

      // Bucket based data
      action.payload.data.goodBuckets.forEach(({ bucket, items }) => {
        state.goodBuckets[bucket] = items;
      });
      action.payload.data.missingMothershipBuckets.forEach(
        ({ bucket, items }) => {
          state.missingMothershipBuckets[bucket] = items;
        },
      );
      state.fechedBuckets += action.payload.data.completed;
    },
  },

  extraReducers: (builder: ActionReducerMapBuilder<MatchingState>) => {
    builder
      .addCase(getMatchingRecords.pending, (state: Draft<MatchingState>) => {
        state.loading = true;
        state.loadingCompleted = false;

        state.goodOrigin = [];
        state.matchingTableRecords = [];
        state.good = [];
        state.missingMothership = [];
        state.duplicates = [];

        state.goodBuckets = [];
        state.missingMothershipBuckets = [];
        state.fechedBuckets = 0;
      })
      .addCase(getMatchingRecords.rejected, (state: Draft<MatchingState>) => {
        state.loading = false;
        state.loadingCompleted = false;
      })
      /**
       * action: PayloadAction<
            boolean,
            string,
            {
              arg: {
                buckets: number[];
              };
              requestId: string;
              requestStatus: 'fulfilled';
            },
            never
          >,
       */
      .addCase(getMatchingRecords.fulfilled, (state: Draft<MatchingState>) => {
        state.loading = false;
        state.loadingCompleted = true;
      })
      .addCase(getAffAssignments.pending, (state: Draft<MatchingState>) => {
        state.loadingAffAssignments = true;
        state.loadingAffAssignmentsCompleted = false;
      })
      .addCase(
        getAffAssignments.fulfilled,
        (
          state: Draft<MatchingState>,
          payload: PayloadAction<FetchAffAssignmentPayload>,
        ) => {
          state.affAssignments = payload.payload.items;
          state.loadingAffAssignments = false;
          state.loadingAffAssignmentsCompleted = true;
        },
      );
  },
});

export const { setScoreRange, setThreshold, updateProgress } =
  matchingSlice.actions;

export default matchingSlice.reducer;
