import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import { green } from '@mui/material/colors';
import KeyIcon from '@mui/icons-material/Key';
import CheckIcon from '@mui/icons-material/Check';

import { useAFSCMESSOContext } from 'contexts/AFSCMESSOContext';
import { LOGGED_IN_STATE } from 'lib/OAuth/CognitoAuthManager';
import { isWhiteList } from 'lib/whitelistCheck';

import { LoginRouteBackground, BackgroundCover } from 'routes/Login';
import FlexContainer from 'components/FlexContainer';
import { Typography } from '@mui/material';

const AzureSSOCB = () => {
  const navigate = useNavigate();
  const { isLoggedIn, authManager, setInProgress, updateSSOStatus } =
    useAFSCMESSOContext();

  const [loading, setLoading] = React.useState(true);
  const [success, setSuccess] = React.useState(false);

  const attemptSSOCompletion = async () => {
    try {
      if (isLoggedIn) return;

      const { state, login, error } = await authManager.completeSSO(
        window.location,
      );

      if (error || state !== LOGGED_IN_STATE) {
        setSuccess(false);
        throw new Error('Failed to login');
      } else {
        const { user, email, name, roles, expiration } = login;

        // removing white list check for now until I repopulate dynamo table. done to allow log in - Martin 9/5
        // const isWhiteListed = await isWhiteList(email, login.access_token);

        setSuccess(true);
        setLoading(false);

        updateSSOStatus({
          isLoggedIn: true,
          // isWhiteListed,
          user,
          email,
          name,
          roles,

          idToken: login.id_token,
          accessToken: login.access_token,
          refreshToken: login.refresh_token,
          expiration,
          expireSecs: login.expire_secs,
        });
      }
    } catch (e) {
      navigate('/login');
    }
  };

  const bootstrap = async () => {
    setInProgress(true);
    await attemptSSOCompletion();
    setInProgress(false);
  };

  useEffect(() => {
    bootstrap();
  }, []);

  return (
    <FlexContainer sx={{ alignItems: 'center', justifyContent: 'center' }}>
      <LoginRouteBackground />
      <BackgroundCover />

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            m: 1,
            position: 'relative',
          }}
        >
          <Fab
            aria-label="authenticating"
            color="primary"
            size="large"
            sx={{
              width: 100,
              height: 100,
              ...(success && {
                bgcolor: green[500],
                '&:hover': {
                  bgcolor: green[700],
                },
              }),
            }}
          >
            <KeyIcon sx={{ width: 50, height: 50 }} />
          </Fab>
          {loading && (
            <CircularProgress
              size={110}
              sx={{
                color: green[500],
                position: 'absolute',
                top: -5,
                zIndex: 1,
              }}
            />
          )}
          <Typography mt={1} color="white">
            Finalizing Authentication ...
          </Typography>
        </Box>
      </Box>
    </FlexContainer>
  );
};

export default AzureSSOCB;
