import { combineReducers } from '@reduxjs/toolkit';

import sessionReducer from './sessionSlice';
import matchingReducer from './matchingSlice';

const rootReducer = combineReducers({
  session: sessionReducer,
  matching: matchingReducer,
});

export default rootReducer;
