import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import MatchingOption from 'components/MatchingOption';
import type { Data } from '../MatchingTable';

interface IMatchingOptionProps {
  threshold?: number;
  item?: Data;
}

export default function MatchingOptionList({
  threshold = 100,
  item,
}: IMatchingOptionProps) {
  if (item) {
    const { matches } = item.origin;
    const duplicatedAfscmeId =
      matches[0].score >= threshold ? matches[0].person_pk : undefined;

    return (
      <Box sx={{ flexGrow: 1 }}>
        <MatchingOption header backgroundColor="yellow" />
        <MatchingOption
          color="#858585"
          backgroundColor="yellow"
          name={item.name}
          afscmeId={item.origin.personpk}
          memberships={item.memberships}
          addresses={item.addresses}
          emails={item.emails}
          phones={item.phones}
          dataIds={[item.parti_id]}
          actionButton={
            <Button variant="contained" onClick={() => {}}>
              Assign New
            </Button>
          }
        />

        {matches.map((match: any) => {
          const { person, addresses, emails, phones, memberships } = match.data;
          const matchName = [person.first_nm, person.middle_nm, person.last_nm]
            .filter((n) => n != null && n.length > 0)
            .join(' ');
          const matchAfscmeId = match.person_pk;

          return (
            <MatchingOption
              key={matchAfscmeId}
              color={
                duplicatedAfscmeId && duplicatedAfscmeId === matchAfscmeId
                  ? 'white'
                  : undefined
              }
              backgroundColor={
                duplicatedAfscmeId && duplicatedAfscmeId === matchAfscmeId
                  ? 'green'
                  : undefined
              }
              name={matchName}
              score={Math.round(match.score)}
              afscmeId={matchAfscmeId}
              memberships={memberships.map((m: any) => ({
                aff_mbr_id: m.mbr_no_local,
                type: m.mbr_type,
                council: '',
                local: '',
                subunit: '',
              }))}
              addresses={addresses.map((a: any) => ({
                id: a.address_pk,
                address1: a.addr1,
                address2: a.addr2,
                city: a.city,
                territory: a.territory,
                zip: a.zipcode,
              }))}
              emails={emails.map((e: any) => ({ email: e.person_email_addr }))}
              phones={phones.map((p: any) => ({ phone: p.phone_no }))}
              dataIds={memberships.map((m: any) => m.unionware_locator)}
              actionButton={
                duplicatedAfscmeId && duplicatedAfscmeId === matchAfscmeId ? (
                  <CheckBoxIcon
                    sx={{ color: 'white', width: 30, height: 30 }}
                  />
                ) : (
                  <Button variant="contained" onClick={() => {}}>
                    Merge
                  </Button>
                )
              }
            />
          );
        })}
      </Box>
    );
  }

  return null;
}

MatchingOptionList.defaultProps = {
  threshold: 100,
  item: undefined,
};
