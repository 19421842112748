import { createTheme } from '@mui/material/styles';

const LightTheme = createTheme({
  breakpoints: {
    values: { xs: 320, sm: 600, md: 1024, lg: 1280, xl: 1440 },
  },
  palette: {
    primary: {
      main: '#388e3c',
    },
    secondary: {
      main: '#29b6f6',
    },
  },
  typography: {
    fontFamily: 'Roboto, Noto, sans-serif',
  },
});

const DarkTheme = createTheme({
  breakpoints: {
    values: { xs: 320, sm: 600, md: 1024, lg: 1280, xl: 1440 },
  },
  typography: {
    fontFamily: 'Roboto, Noto, sans-serif',
  },
});

export { LightTheme, DarkTheme };
