import React, { useState } from 'react';

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useAFSCMESSOContext } from 'contexts/AFSCMESSOContext';

const HeaderContainer = styled(Stack)`
  width: 100%;
  height: 55px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgb(194, 24, 91);
  color: white;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
`;

const Header = () => {
  const { logout } = useAFSCMESSOContext();

  const [isDrawerOpened, setDrawerOpened] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isActionMenuOpened: boolean = Boolean(anchorEl);

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpened(!isDrawerOpened);
  };

  const handleActionsButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionsMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    logout();
  };

  return (
    <HeaderContainer>
      <Stack flexDirection="row">
        <IconButton onClick={toggleDrawer}>
          <MenuIcon sx={{ color: 'white' }} />
        </IconButton>

        <Typography variant="h6" textTransform="uppercase" margin="auto">
          MatchBack
        </Typography>
      </Stack>

      <Stack>
        <IconButton
          id="actions-button"
          aria-label="action-items"
          aria-controls={isActionMenuOpened ? 'actions-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isActionMenuOpened ? 'true' : undefined}
          onClick={handleActionsButtonClick}
        >
          <MoreVertIcon sx={{ color: 'white' }} />
        </IconButton>
        <Menu
          id="actions-menu"
          anchorEl={anchorEl}
          open={isActionMenuOpened}
          onClose={handleActionsMenuClose}
          MenuListProps={{
            'aria-labelledby': 'actions-button',
          }}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Stack>

      <SwipeableDrawer
        anchor="left"
        open={isDrawerOpened}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <Typography>Drawer</Typography>
        </Box>
      </SwipeableDrawer>
    </HeaderContainer>
  );
};

export default Header;
