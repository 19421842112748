import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAFSCMESSOContext } from 'contexts/AFSCMESSOContext';
import { saveLocation } from 'lib/routing';

import Loading from 'components/Loading';

const ProtectedRoute = () => {
  const { inProgress, isLoggedIn } = useAFSCMESSOContext();

  saveLocation();

  if (isLoggedIn) {
    return inProgress ? <Loading /> : <Outlet />;
  }

  return <Navigate to="/login" replace />;
};

export default ProtectedRoute;
