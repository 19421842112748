import { configureStore } from '@reduxjs/toolkit';
import loggerMiddleware from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';

import ReduxPersistConfig from 'config/reduxPersist';
import rootReducer from 'store/slices';

export default function createStore() {
  const persistedReducer = persistReducer(
    ReduxPersistConfig.rootConfig,
    rootReducer,
  );

  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        loggerMiddleware,
      ),
    devTools: process.env.NODE_ENV !== 'production',
  });

  const storePersistor = persistStore(store);

  return {
    store,
    storePersistor,
  };
}

export const { store, storePersistor } = createStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
