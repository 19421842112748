import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import LoginForm from 'components/LoginForm';
import FlexContainer from 'components/FlexContainer';

const backgroundImageUrl = '../../assets/img/ibm_360.jpg';
export const LoginRouteBackground = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url(${backgroundImageUrl});
  background-size: cover;
  background-position: center;
  image-rendering: pixelated;
  filter: contrast(30%) saturate(0%);
`;

export const BackgroundCover = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: black;
  opacity: 0.25;
`;

const LoginRoute = () => {
  return (
    <FlexContainer sx={{ alignItems: 'center', justifyContent: 'center' }}>
      <LoginRouteBackground />
      <BackgroundCover />

      <LoginForm />
    </FlexContainer>
  );
};

export default LoginRoute;
