import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { store, storePersistor } from 'store';
import { AFSCMESSOContextProvider } from 'contexts/AFSCMESSOContext';
import AppRoutes from 'routes/appRoutes';

import { LightTheme } from 'theme';

const App = () => (
  <ThemeProvider theme={LightTheme}>
    <CssBaseline>
      <Provider store={store}>
        <PersistGate loading={null} persistor={storePersistor}>
          <BrowserRouter>
            <AFSCMESSOContextProvider>
              <AppRoutes />
            </AFSCMESSOContextProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </CssBaseline>
  </ThemeProvider>
);

export default App;
