import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const FlexContainer = styled(Box)`
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 100vh;
  padding: 10px;
  padding-top: 60px;
`;

export default FlexContainer;
