import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import FlexContainer from 'components/FlexContainer';

const Loading = () => {
  return (
    <FlexContainer sx={{ alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress size={60} />
    </FlexContainer>
  );
};

export default Loading;
