import React, { memo } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface IMatchingOptionProps {
  header?: boolean;
  color?: string;
  backgroundColor?: string;
  name?: string;
  score?: number;
  afscmeId?: string;
  memberships?: any[];
  addresses?: any[];
  emails?: any[];
  phones?: any[];
  dataIds?: string[];
  actionButton?: React.ReactElement | null;
}

function MatchingOption(props: IMatchingOptionProps) {
  const {
    header,
    color,
    backgroundColor,
    name,
    score,
    afscmeId,
    memberships,
    addresses,
    emails,
    phones,
    dataIds,
    actionButton,
  } = props;

  const emailsStr = emails?.map((e) => e.email).join(', ');
  const phonesStr = phones?.map((p) => p.phone).join(', ');
  const affiliates = memberships?.map((membership, i) => {
    let str = '';
    if (membership.council) str += `C${Math.trunc(membership.council)} `;
    if (membership.local) str += `L${Math.trunc(membership.local)} `;
    if (membership.subunit) str += `S${Math.trunc(membership.subunit)} `;
    if (membership.type) str += ` | ${membership.type}`;

    return { key: i, locator: str, id: membership.aff_mbr_id };
  });

  return (
    <Grid container p={3} sx={{ color, backgroundColor }}>
      <Grid item xs={1} display="flex" justifyContent="center">
        {header ? (
          <h5>AFSCME Member ID</h5>
        ) : (
          <Typography>{afscmeId}</Typography>
        )}
      </Grid>
      <Grid item xs={1} display="flex" justifyContent="center">
        {header ? <h5>Match Pts</h5> : <h5>{score ? `${score}` : null}</h5>}
      </Grid>
      <Grid item xs={2}>
        {header ? (
          <h5>Memberships</h5>
        ) : (
          affiliates?.map((affiliate) => (
            <Stack key={affiliate.key} flexDirection="row" columnGap={1}>
              <Typography>{`${affiliate.locator}${
                affiliate.id ? ' | ' : ''
              }`}</Typography>
              {affiliate.id && <h5>ID: {affiliate.id}</h5>}
            </Stack>
          ))
        )}
      </Grid>
      <Grid item xs={1}>
        {header ? <h5>Name</h5> : <Typography>{name}</Typography>}
      </Grid>
      <Grid item xs={2}>
        {header ? (
          <h5>Address</h5>
        ) : (
          <Stack flexDirection="column" rowGap={2}>
            {addresses?.map((a) => {
              return (
                <div key={a.id}>
                  <Typography>{a.address1}</Typography>
                  <Typography>{a.address2}</Typography>
                  <Typography>
                    {a.city} {a.territory}, {a.zip}
                  </Typography>
                </div>
              );
            })}
          </Stack>
        )}
      </Grid>
      <Grid item xs={2}>
        {header ? (
          <h5>Contact</h5>
        ) : (
          <>
            <Typography>{phonesStr}</Typography>
            <Typography>{emailsStr}</Typography>
          </>
        )}
      </Grid>
      <Grid item xs={2}>
        {header ? (
          <h5>Data Key</h5>
        ) : (
          <Stack flexDirection="column">
            {dataIds?.map((id) => (
              <Typography key={id}>{id}</Typography>
            ))}
          </Stack>
        )}
      </Grid>
      <Grid item xs={1} display="flex" justifyContent="center">
        <div>{actionButton}</div>
      </Grid>
    </Grid>
  );
}

MatchingOption.defaultProps = {
  header: false,
  color: 'black',
  backgroundColor: 'white',
  name: null,
  score: null,
  afscmeId: null,
  memberships: [],
  addresses: [],
  emails: [],
  phones: [],
  dataIds: [],
  actionButton: null,
};

export default memo(MatchingOption);
