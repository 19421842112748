const keys = [
  'UnionWare Mothership ID',
  'AFSCME Member ID',
  'Type',
  'Council',
  'Local',
  'SubUnit',
  'First Name',
  'Middle Name',
  'Last Name',
  'Suffix',
  'Address1',
  'Address2',
  'City',
  'State',
  'Zip',
  'TransDate',
  'partiId',
];
const duplicateKeys = [
  'Disposition',
  'Fields matched',
  'UnionWare Mothership ID',
  'AFSCME Member ID',
  'Affiliate Mbr ID',
  'Status',
  'Type',
  'Council',
  'Local',
  'SubUnit',
  'First Name',
  'Middle Name',
  'Last Name',
  'Suffix',
  'Address1',
  'Address2',
  'City',
  'State',
  'Zip',
  'Phone',
  'Email',
  'TransDate',
  'partiId',
];
const affAssignmentKeys = [
  'Aff_pk',
  'Aff_status',
  'Council',
  'Local',
  'SubUnit',
  'Aff_stateNat_type',
  'Aff_abbreviated_nm',
  'Aff_type',
  'Jurisdiction',
  'Aff_afscme_region',
  'Aff_afscme_leg_district',
  'Parent_aff_fk',
  'partiId',
  'TransDate',
];

const statusCodes = [
  { statusid: 31002, name: 'Inactive' },
  { statusid: 31003, name: 'Temporary' },
  { statusid: 83070, name: 'Pending' },
  { statusid: 31001, name: 'Active' },
];

const extractFields = (item: any) => {
  const { addresses, memberships, phones, emails } = item;

  let addressObj;
  const addressKeys = addresses ? Object.keys(addresses) : [];
  if (addressKeys.length > 0) {
    addressObj = addresses[addressKeys[0]];
  }

  let membershipObj: any;
  const membershipKeys = memberships ? Object.keys(memberships) : [];
  if (membershipKeys.length > 0) {
    membershipObj = memberships[membershipKeys[0]];
  }

  let phoneObj;
  const phoneKeys = phones ? Object.keys(phones) : [];
  if (phoneKeys.length > 0) {
    phoneObj = phones[phoneKeys[0]];
  }

  let emailObj;
  const emailKeys = emails ? Object.keys(emails) : [];
  if (emailKeys.length > 0) {
    emailObj = emails[emailKeys[0]];
  }

  return {
    Disposition: 'New',
    'Fields matched': '',
    'UnionWare Mothership ID': item.mothership_id,
    'AFSCME Member ID': item.personpk,
    'Affiliate Mbr ID': membershipObj?.aff_mbr_id,
    Status: statusCodes.find(
      (item) => item.statusid === membershipObj?.statusId,
    )?.name,
    Type: membershipObj?.type,
    Council: membershipObj?.council ?? item.council,
    Local: membershipObj?.local ?? item.local,
    SubUnit: membershipObj?.subunit ?? item.subunit,
    'First Name': item.firstname,
    'Middle Name': item.middlename,
    'Last Name': item.lastname,
    Suffix: item.suffix,
    Address1: addressObj?.address1,
    Address2: addressObj?.address2,
    City: addressObj?.city,
    State: addressObj?.territory,
    Zip: addressObj?.zip,
    Phone: phoneObj?.phone,
    Email: emailObj?.email,
    TransDate: item.transDate,
    partiId: item.parti_id,
    Aff_pk: item.aff_pk,
    Aff_status: item.aff_status,
    Aff_stateNat_type: item.aff_statenat_type,
    Aff_abbreviated_nm: item.aff_abbreviated_nm,
    Aff_type: item.aff_type,
    Jurisdiction: item.jurisdiction,
    Aff_afscme_region: item.aff_afscme_region,
    Aff_afscme_leg_district: item.aff_afscme_leg_district,
    Parent_aff_fk: item.parent_aff_fk,
  };
};

const extractFieldsFromMatch = (
  match: any,
  partiId: string,
  transDate: string,
) => {
  const {
    comparison_vector: cVector,
    data: {
      addresses,
      memberships,
      emails,
      phones,
      mothership_id: mothershipId,
      person,
    },
  } = match;

  const fieldMatched = Object.entries(cVector)
    .filter(([key, value]: any[]) => value > 0)
    .map(([key, value]: any[]) => {
      let statement;

      switch (key) {
        case 'name':
          statement = 'Name';
          break;
        case 'address':
          statement = 'Address';
          break;
        case 'zip':
          statement = 'Zip';
          break;
        case 'phone':
          statement = 'Phone';
          break;
        case 'email':
          statement = 'Email';
          break;
        case 'dob':
          statement = 'DOB';
          break;
        case 'affmbrid':
          statement = 'Aff Mbr ID';
          break;
        default:
          return undefined;
      }

      if (value > 0 && value < 1) statement += ` (${value.toFixed(1)})`;
      if (value > 1) statement += ` (x${value.toFixed(1)})`;

      return statement;
    })
    .filter((f) => f)
    .join(', ');

  return {
    partiId,
    Disposition: 'Existing',
    'Fields matched': fieldMatched,
    'UnionWare Mothership ID': mothershipId,
    'AFSCME Member ID': person.person_pk,
    'Affiliate Mbr ID': memberships[0].mbr_no_local,
    Status: memberships[0].mbr_status,
    Type: memberships[0].mbr_type,
    Council: memberships[0].council,
    Local: memberships[0].local,
    SubUnit: memberships[0].subunit,
    'First Name': person.first_nm,
    'Middle Name': person.middle_nm,
    'Last Name': person.last_nm,
    Suffix: person.suffix_nm,
    Address1: addresses[0].addr1,
    Address2: addresses[0].addr2,
    City: addresses[0].city,
    State: addresses[0].territory,
    Zip: addresses[0].zipcode,
    Phone: phones.length > 0 ? phones[0].phone_no : '',
    Email: emails.length > 0 ? emails[0].person_email_addr : '',
    TransDate: transDate,
  };
};

export const GOOD = 'good';
export const DUPLICATE = 'duplicate';
export const MISSING_MOTHERSHIP = 'missing_mothership';
export const AFF_ASSIGNMENT = 'aff_assignment';

function generateTextDelimited(
  items: any[],
  category: string = GOOD,
  delimiter = '\t',
) {
  let tsvHeader = '';
  let tsvBody = '';

  let tsvKeys = keys;
  if (category === MISSING_MOTHERSHIP) tsvKeys = keys.slice(2);
  if (category === DUPLICATE) tsvKeys = duplicateKeys;
  if (category === AFF_ASSIGNMENT) tsvKeys = affAssignmentKeys;

  if (items.length > 0) {
    tsvHeader = `${tsvKeys.join(delimiter)}\n`;
    tsvBody = items.reduce((accumulator, current) => {
      const line = tsvKeys
        .map((key) =>
          JSON.stringify(current[key] || '').replace(/[\t\n]/g, ' '),
        )
        .join(delimiter);

      accumulator += line;
      accumulator += '\n';

      return accumulator;
    }, '');
  }

  return {
    tsvHeader,
    tsvBody,
  };
}

export { extractFields, extractFieldsFromMatch, generateTextDelimited };
