/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
// import { visuallyHidden } from '@mui/utils';

import MatchingOptionList from 'components/MatchingOptionList';

export interface Data {
  parti_id: string;
  personpk: number;
  score: number;
  name: string;
  addresses: any[];
  emails: any[];
  phones: any[];
  memberships: any[];
  origin: any;
}

function createData(records: any[]): Data[] {
  return records.map((item) => {
    const name = [item.firstname, item.middlename, item.lastname]
      .filter((n) => n != null)
      .join(' ');
    const addresses = Object.values(item.addresses);
    const emails = Object.values(item.emails);
    const phones = Object.values(item.phones);
    const memberships = Object.values(item.memberships);

    return {
      parti_id: item.parti_id,
      personpk: item.personpk,
      score: Math.round(item.matches[0].score),
      name,
      addresses,
      emails,
      phones,
      memberships,
      origin: item,
    };
  });
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string | null | any },
  b: { [key in Key]: number | string | null | any },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  width: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'score',
    numeric: true,
    disablePadding: false,
    label: 'Match Pts',
    width: '120px',
  },
  {
    id: 'membership',
    numeric: false,
    disablePadding: false,
    label: 'Membership',
    width: '150px',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    width: '150px',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'Address',
    width: '200px',
  },
  {
    id: 'contact',
    numeric: false,
    disablePadding: false,
    label: 'Contact',
    width: '200px',
  },
];

interface MatchingTableHeadProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function MatchingTableHead(props: MatchingTableHeadProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            style={{ minWidth: headCell.width }}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface MatchingTableToolbarProps {
  numSelected: number;
}

function MatchingTableToolbar(props: MatchingTableToolbarProps) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Matching Resolutions
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MatchingTable({
  records,
  threshold = 100,
}: {
  threshold: number;
  records: any[];
}) {
  const rows = React.useMemo(() => createData(records), [records]);

  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState<Data>();

  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<string>('score');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.parti_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (partiId: string) => selected.indexOf(partiId) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort<Data>(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [rows, order, orderBy, page, rowsPerPage],
  );

  const handleRowClick = (
    event: React.MouseEvent<unknown>,
    partiId: string,
  ) => {
    setItem(visibleRows.find((r) => r.parti_id === partiId));
    setOpen(true);
    // const selectedIndex = selected.indexOf(partiId);
    // let newSelected: readonly string[] = [];
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, partiId);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1),
    //   );
    // }
    // setSelected(newSelected);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <MatchingTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 1200 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <MatchingTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.parti_id);
                const labelId = `matching-table-checkbox-${index}`;
                const emailContacts = row.emails.map((i) => i.email).join(', ');
                const phoneContacts = row.phones.map((i) => i.phone).join(', ');
                const memberships = row.memberships.map((membership, i) => {
                  let str = '';
                  if (membership.council)
                    str += `C${Math.trunc(membership.council)} `;
                  if (membership.local)
                    str += `L${Math.trunc(membership.local)} `;
                  if (membership.subunit)
                    str += `S${Math.trunc(membership.subunit)} `;
                  if (membership.type) str += ` | ${membership.type}`;

                  return { key: i, locator: str, id: membership.aff_mbr_id };
                });

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleRowClick(event, row.parti_id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.parti_id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{row.score}</Typography>
                      {row.personpk === row.origin.matches[0].personpk ? (
                        <Chip label="Duplicate" color="primary" size="small" />
                      ) : (
                        <Chip label="New" color="secondary" size="small" />
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <Stack flexDirection="column">
                        {memberships.map((m) => (
                          <Stack key={m.key} flexDirection="row" columnGap={1}>
                            <Typography>{`${m.locator}${
                              m.id ? ' | ' : ''
                            }`}</Typography>
                            {m.id && <h5>ID: {m.id}</h5>}
                          </Stack>
                        ))}
                      </Stack>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>{row.name}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Stack flexDirection="column">
                        {row.addresses.map((address) => {
                          return (
                            <div key={address.id}>
                              <Typography>{address.address1}</Typography>
                              <Typography>{address.address2}</Typography>
                              <Typography>
                                {address.city} {address.territory},{' '}
                                {address.zip}
                              </Typography>
                            </div>
                          );
                        })}
                      </Stack>
                    </TableCell>
                    <TableCell align="left">
                      <Stack flexDirection="column">
                        <Typography>{phoneContacts}</Typography>
                        <Typography>{emailContacts}</Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Matching Option Select
            </Typography>
          </Toolbar>
        </AppBar>

        <Stack>
          <MatchingOptionList threshold={threshold} item={item} />
        </Stack>
      </Dialog>
    </Box>
  );
}
