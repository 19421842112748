export interface IBucketData {
  good: any[];
  goodBuckets: any[];
  missingMothership: any[];
  missingMothershipBuckets: any[];
  completed: number;
}

const FETCH_RECORDS_URL = `${process.env.REACT_APP_API_BASE_URL}/person`;
const MAX_BUCKETS = 2000;
const MAX_BUCKET_PER_ONE_REQUEST = 50;
const AFF_ASSIGNMENT_BUCKET = 2001;

const getChunks = (arr: any[], n: number) => {
  const chunks = [];
  for (let i = 0; i < arr.length; i += n) {
    chunks.push(arr.slice(i, i + n));
  }

  return chunks;
};

export const fetchAffAssignments = async (
  accessToken: string,
): Promise<any> => {
  const resp = await fetch(FETCH_RECORDS_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      buckets: [AFF_ASSIGNMENT_BUCKET],
      category: 'AFF_ASSIGNMENT',
    }),
  });
  const jsonResp = await resp.json();

  return jsonResp.buckets.length > 0
    ? jsonResp.buckets[0]
    : { bucket: AFF_ASSIGNMENT_BUCKET, items: [] };
};

export const fetchBuckets = async (
  accessToken: string,
  buckets: number[],
  setProgress?: (data: IBucketData) => void,
): Promise<boolean> => {
  const bucketChunks = getChunks(
    buckets.length > 0
      ? buckets
      : new Array(MAX_BUCKETS).fill(0).map((value, i) => i),
    MAX_BUCKET_PER_ONE_REQUEST,
  );

  await Promise.all(
    bucketChunks.map(async (chunk) =>
      fetchBucketChunk(accessToken, chunk, setProgress),
    ),
  );

  return true;
};

export const fetchBucketChunk = async (
  accessToken: string,
  chunk: number[],
  setProgress?: (data: IBucketData) => void,
): Promise<any> => {
  const resp = await fetch(FETCH_RECORDS_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ buckets: chunk }),
  });
  const jsonResp = await resp.json();

  const {
    buckets: bucketBasedData,
    completedBuckets,
    remainedBuckets,
  } = jsonResp;

  const itemsData: IBucketData = bucketBasedData.reduce(
    (
      accumulator: {
        good: any[];
        goodBuckets: any[];
        missingMothership: any[];
        missingMothershipBuckets: any[];
      },
      current: any,
    ) => {
      accumulator.good.push(...current.good);
      accumulator.goodBuckets.push({
        bucket: current.bucket,
        items: current.good,
      });
      accumulator.missingMothership.push(...current.missingMothership);
      accumulator.missingMothershipBuckets.push({
        bucket: current.bucket,
        items: current.missingMothership,
      });

      return accumulator;
    },
    {
      good: [],
      goodBuckets: [],
      missingMothership: [],
      missingMothershipBuckets: [],
      completed: completedBuckets.length,
    },
  );
  if (setProgress) setProgress(itemsData);

  if (completedBuckets.length === chunk.length) {
    return itemsData;
  }

  const remainedItemsData = await fetchBucketChunk(
    accessToken,
    remainedBuckets,
    setProgress,
  );

  return {
    good: itemsData.good.concat(remainedItemsData.good),
    goodBuckets: itemsData.goodBuckets.concat(remainedItemsData.goodBuckets),
    missingMothership: itemsData.missingMothership.concat(
      remainedItemsData.missingMothership,
    ),
    missingMothershipBuckets: itemsData.missingMothershipBuckets.concat(
      remainedItemsData.missingMothershipBuckets,
    ),
    completed: itemsData.completed + remainedItemsData.completed,
  };
};
