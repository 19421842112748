import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import { green } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

import Container from 'components/Container';
import CircularProgressWithLabel from 'components/CircularProgressWithLabel';
import MatchingTable from 'components/MatchingTable';

import {
  extractFields,
  extractFieldsFromMatch,
  generateTextDelimited,
  GOOD,
  DUPLICATE,
  MISSING_MOTHERSHIP,
  AFF_ASSIGNMENT,
} from 'lib/generateTextDelimited';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getWhiteListStatus } from 'store/slices/sessionSlice';
import {
  setScoreRange,
  setThreshold,
  getMatchingRecords,
  getAffAssignments,
  TOTAL_BUCKETS,
} from 'store/slices/matchingSlice';
import {
  getScoreRangeFrom,
  getScoreRangeTo,
  getThreshold,
  matchingTableRecords,
  goodItems,
  // goodBuckets,
  duplicateItems,
  missingMothershipItems,
  // missingMothershipBuckets,
  affAssignmentItems,
  loading,
  loadingCompleted,
  loadingAffAssignments,
  loadingAffAssignmentsCompleted,
  fetchedBuckets,
} from 'store/selectors/matching';

const ReportPaper = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  width: 200,
  height: 200,
  lineHeight: '60px',
  paddingBlock: '10px',
  marginInline: '10px',
}));

function Home() {
  const dispatch = useAppDispatch();

  const whitelisted = useAppSelector(getWhiteListStatus);

  const initialScoreFrom = useAppSelector(getScoreRangeFrom);
  const initialScoreTo = useAppSelector(getScoreRangeTo);
  const initialThreshold = useAppSelector(getThreshold);

  const isFetching = useAppSelector(loading);
  const isFetchingCompleted = useAppSelector(loadingCompleted);

  const isFetchingAffAssignment = useAppSelector(loadingAffAssignments);
  const isFetchingAffAssignmentCompleted = useAppSelector(
    loadingAffAssignmentsCompleted,
  );

  const completedBuckets = useAppSelector(fetchedBuckets);

  const matchingRecords = useAppSelector(matchingTableRecords);
  const good = useAppSelector(goodItems);
  const missingMothership = useAppSelector(missingMothershipItems);
  const duplicates = useAppSelector(duplicateItems);
  const affAssignments = useAppSelector(affAssignmentItems);
  // const goodBucketBased = useAppSelector(goodBuckets);
  // const missingMothershipBucketBased = useAppSelector(missingMothershipBuckets);

  const [scoreRange, setInputScoreRange] = useState<{
    from: number;
    to: number;
  }>({
    from: initialScoreFrom,
    to: initialScoreTo,
  });
  const [threshold, setInputThreshold] = useState(initialThreshold);

  useEffect(() => {
    if (
      !isFetching &&
      !isFetchingCompleted &&
      !isFetchingAffAssignment &&
      !isFetchingAffAssignmentCompleted
    ) {
      dispatch(getMatchingRecords({ buckets: [] }));
      dispatch(getAffAssignments());
    }
  }, []);

  const handleDownloadRecords = (content: any[], category: string = GOOD) => {
    let contentForDownload: any[] = [];

    if (category === DUPLICATE) {
      contentForDownload = content.flatMap((item) => [
        extractFields(item),
        extractFieldsFromMatch(item.matches[0], item.parti_id, item.transDate),
      ]);
    } else {
      contentForDownload = content.map(extractFields);
    }

    const { tsvHeader, tsvBody } = generateTextDelimited(
      contentForDownload,
      category,
    );
    const tsvContent = tsvHeader + tsvBody;

    let exportedFilename = 'Match Back File.tsv';
    if (category === MISSING_MOTHERSHIP)
      exportedFilename = 'Missing UnionWare ID File.tsv';
    if (category === DUPLICATE) exportedFilename = 'Duplicates File.tsv';
    if (category === AFF_ASSIGNMENT)
      exportedFilename = 'Aff Assignment File.tsv';

    const blob = new Blob([tsvContent], { type: 'text/tsv;charset=utf-8;' });

    // @ts-ignore
    if (navigator.msSaveBlob) {
      // @ts-ignore
      navigator.msSaveBlob(blob, exportedFilename); // IE 10+
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportedFilename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const handleUpdateFilterMetrics = () => {
    dispatch(setScoreRange(scoreRange));
    dispatch(setThreshold({ threshold }));
  };

  return (
    <Container>
      <Stack
        flexDirection="row"
        padding={2}
        sx={{ border: '2px solid #CD045A' }}
      >
        <ReportPaper>
          <Stack
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            height="100%"
          >
            <Typography>Good Records: {good.length}</Typography>
            <Stack
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ margin: 1 }}
            >
              {isFetching ? (
                <CircularProgressWithLabel
                  size={50}
                  value={(completedBuckets / TOTAL_BUCKETS) * 100}
                  sx={{ color: green[500], zIndex: 1 }}
                />
              ) : (
                <Button
                  disabled={isFetching}
                  variant="outlined"
                  onClick={() => {
                    handleDownloadRecords(good);
                  }}
                >
                  Download A Report
                </Button>
              )}
            </Stack>
          </Stack>
        </ReportPaper>

        <ReportPaper>
          <Stack
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            height="100%"
          >
            <Typography>
              Missing Mothership ID Records: {missingMothership.length}
            </Typography>
            <Stack
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ margin: 1 }}
            >
              {isFetching ? (
                <CircularProgressWithLabel
                  size={50}
                  value={(completedBuckets / TOTAL_BUCKETS) * 100}
                  sx={{ color: green[500], zIndex: 1 }}
                />
              ) : (
                <Button
                  disabled={isFetching}
                  variant="outlined"
                  onClick={() => {
                    handleDownloadRecords(
                      missingMothership,
                      MISSING_MOTHERSHIP,
                    );
                  }}
                >
                  Download A Report
                </Button>
              )}
            </Stack>
          </Stack>
        </ReportPaper>

        <ReportPaper>
          <Stack
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            height="100%"
          >
            <Typography>Duplicate Records: {duplicates.length}</Typography>
            <Stack
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ margin: 1 }}
            >
              {isFetching ? (
                <CircularProgressWithLabel
                  size={50}
                  value={(completedBuckets / TOTAL_BUCKETS) * 100}
                  sx={{ color: green[500], zIndex: 1 }}
                />
              ) : (
                <Button
                  disabled={isFetching}
                  variant="outlined"
                  onClick={() => {
                    handleDownloadRecords(duplicates, DUPLICATE);
                  }}
                >
                  Download A Report
                </Button>
              )}
            </Stack>
          </Stack>
        </ReportPaper>

        <ReportPaper>
          <Stack
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            height="100%"
          >
            <Typography>
              Aff Assignment Records: {affAssignments.length}
            </Typography>
            <Stack
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ margin: 1 }}
            >
              {isFetchingAffAssignment ? (
                <CircularProgressWithLabel
                  size={50}
                  value={(completedBuckets / TOTAL_BUCKETS) * 100}
                  sx={{ color: green[500], zIndex: 1 }}
                />
              ) : (
                <Button
                  disabled={isFetchingAffAssignment}
                  variant="outlined"
                  onClick={() => {
                    handleDownloadRecords(affAssignments, AFF_ASSIGNMENT);
                  }}
                >
                  Download A Report
                </Button>
              )}
            </Stack>
          </Stack>
        </ReportPaper>

        {whitelisted && (
          <Stack
            flexDirection="column"
            alignItems="start"
            marginInlineStart={2}
          >
            <Stack flexDirection="row" alignItems="center">
              <Typography>Match Pts: </Typography>
              <TextField
                type="number"
                label="From"
                id="outlined-start-adornment"
                sx={{ m: 1, width: '20ch' }}
                inputProps={{ min: 0 }}
                value={scoreRange.from}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setInputScoreRange((prev) => ({
                    ...prev,
                    from: e.target.valueAsNumber,
                  }));
                }}
              />
              <TextField
                type="number"
                label="To"
                id="outlined-start-adornment"
                sx={{ m: 1, width: '20ch' }}
                inputProps={{ min: 0 }}
                value={scoreRange.to}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setInputScoreRange((prev) => ({
                    ...prev,
                    to: e.target.valueAsNumber,
                  }));
                }}
              />
            </Stack>

            <Stack flexDirection="row">
              <TextField
                label="Member Type"
                id="outlined-start-adornment"
                sx={{ m: 1, ml: 0, width: '20ch' }}
              />
              <TextField
                label="Council"
                id="outlined-start-adornment"
                sx={{ m: 1, width: '20ch' }}
              />
              <TextField
                label="Local"
                id="outlined-start-adornment"
                sx={{ m: 1, width: '20ch' }}
              />
            </Stack>

            <Stack flexDirection="row" alignItems="center">
              <TextField
                type="number"
                label="Threshold"
                id="outlined-start-adornment"
                sx={{ m: 1, ml: 0, width: '20ch' }}
                inputProps={{ min: 0 }}
                value={threshold}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setInputThreshold(e.target.valueAsNumber);
                }}
              />
              <Stack>
                <Button variant="outlined" onClick={handleUpdateFilterMetrics}>
                  Update
                </Button>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>

      {whitelisted && (
        <Stack flexDirection="row" marginTop={2}>
          <MatchingTable threshold={threshold} records={matchingRecords} />
        </Stack>
      )}
    </Container>
  );
}

export default Home;
