import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useAFSCMESSOContext } from 'contexts/AFSCMESSOContext';

interface ILoginFormProps {}

const LoginForm: React.FC<ILoginFormProps> = (props: ILoginFormProps) => {
  const { authManager } = useAFSCMESSOContext();

  const handleSSO = async () => {
    try {
      await authManager.attemptAFSCMESSO();
    } catch (err) {
      throw new Error(`could not log in: ${err}`);
    }
  };

  return (
    <Card sx={{ maxWidth: 345, zIndex: 50, borderRadius: 2 }}>
      <CardContent sx={{ padding: 0, textAlign: 'center' }}>
        <Typography
          gutterBottom
          component="div"
          variant="h5"
          sx={{
            padding: '12px 20px',
            fontWeight: 400,
            textTransform: 'uppercase',
            color: 'white',
            backgroundColor: '#6200ee',
          }}
        >
          MatchBack App
        </Typography>

        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ margin: 'auto', marginTop: 2, maxWidth: '10rem' }}
        >
          Sign in with your AFSCME credentials
        </Typography>

        <Button
          disableElevation
          size="small"
          variant="contained"
          sx={{
            marginTop: 2,
            fontWeight: 400,
            letterSpacing: 2,
            paddingX: 2,
            paddingY: 1,
          }}
          onClick={handleSSO}
        >
          AFSCME Staff
        </Button>
      </CardContent>
    </Card>
  );
};

export default LoginForm;
