import { createSlice, PayloadAction } from '@reduxjs/toolkit'; // ActionReducerMapBuilder
import { Draft } from 'immer';

import { RootState } from 'store';

interface SessionState {
  isLoggedIn: boolean;
  // isWhiteListed: boolean;

  user?: string;
  email?: string;
  name?: string;
  roles?: any;

  token: {
    idToken?: string;
    accessToken?: string;
    refreshToken?: string;
    expiration?: any;
    expireSecs?: any;
  };

  location?: {
    l: string;
    s: string;
  };

  // eslint-disable-next-line no-undef
  userInfo?: AFSCME.IUser;
}

const initialState: SessionState = {
  isLoggedIn: false,
  // isWhiteListed: false,
  token: {},
  userInfo: undefined,
};

type SetLoggedInActionPayload = {
  isLoggedIn: boolean;
  // isWhiteListed: boolean;
  user: string;
  email: string;
  name: string;
  roles: any;

  idToken: string;
  accessToken: string;
  refreshToken: string;
  expiration: any;
  expireSecs: any;
};

export const sessionSlice = createSlice({
  name: 'session',

  initialState,

  reducers: {
    updateAuthStatus: (
      state: Draft<SessionState>,
      action: PayloadAction<SetLoggedInActionPayload>,
    ) => {
      const {
        isLoggedIn,
        // isWhiteListed,
        user,
        email,
        name,
        roles,
        idToken,
        accessToken,
        refreshToken,
        expiration,
        expireSecs,
      } = action.payload;

      state.isLoggedIn = isLoggedIn;
      // state.isWhiteListed = isWhiteListed;
      if (user) state.user = user;
      if (email) state.email = email;
      if (name) state.name = name;
      if (roles) state.roles = roles;

      state.token = {
        idToken,
        accessToken,
        refreshToken,
        expiration,
        expireSecs,
      };
    },
    logout: (state: Draft<SessionState>, action: PayloadAction<void>) => {
      localStorage.clear();
      delete state.user;
      delete state.email;
      delete state.name;
      delete state.roles;
      state.token = {};

      state.isLoggedIn = false;
    },
  },

  // builder: ActionReducerMapBuilder<SessionState>
  extraReducers: () => {
    // builder
  },
});

// Selectors
export const getIsLoggedIn = (state: RootState): boolean =>
  state.session.isLoggedIn;
export const getTokens = (state: RootState): any => state.session.token;
export const getUserEmail = (state: RootState): any => state.session.email;
export const getWhiteListStatus = (state: RootState): boolean => true; // hard coded for now until dynamodb table is reconstituted
// state.session.isWhiteListed;

// Actions
export const { updateAuthStatus, logout } = sessionSlice.actions;

export default sessionSlice.reducer;
