import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { jwtDecode } from 'jwt-decode';
import PKCE from 'js-pkce';

const DO_PKCE = true;
// const COGNITO_DOMAIN = 'https://mindues.auth.us-east-1.amazoncognito.com';
const COGNITO_DOMAIN = 'https://afscme-dad.auth.us-east-1.amazoncognito.com';
const COGNITO_IDENTITY_PROVIDER = 'Azure';

export const COGNITO_CB_ENDPOINT = 'azure_sso_cb';

export const LOGGED_IN_STATE = 'LOGGED IN';
export const LOGGED_OUT_STATE = 'LOGGED OUT';

const activityTimer = (logout: any) => {
  const resetMove = () => {
    document.addEventListener('mousemove', activity, { passive: true });
  };

  const LOGOUT_DELAY = 30 * 60 * 1000; // 30 minutes
  const MOUSE_MOVE_INTERVAL = 5000;
  let time = setTimeout(logout, LOGOUT_DELAY);
  let movetime = setTimeout(resetMove, MOUSE_MOVE_INTERVAL);
  let canceled = false;

  const activity = () => {
    if (canceled) return;
    clearTimeout(time);
    time = setTimeout(logout, LOGOUT_DELAY);
    document.removeEventListener('mousemove', activity);
    clearTimeout(movetime);
    movetime = setTimeout(resetMove, MOUSE_MOVE_INTERVAL);
  };

  const cancel = () => {
    canceled = true;
    clearTimeout(time);
    clearTimeout(movetime);
    [
      'load',
      'mousedown',
      'keydown',
      'touchstart',
      'click',
      'scroll',
      'keypress',
      'mousemove',
    ].forEach((e) => document.removeEventListener(e, activity));
  };

  [
    'load',
    'mousedown',
    'keydown',
    'touchstart',
    'click',
    'scroll',
    'keypress',
  ].forEach((e) => document.addEventListener(e, activity, { passive: true }));

  return { cancel };
};

export class CognitoAuthMgr {
  pkce: any;

  pool: any;

  userPool: AmazonCognitoIdentity.CognitoUserPool;

  attemptInProgress: boolean;

  loginCB: any;

  logoutCB: any;

  resultCBs: any;

  savedLocation: any;

  SSO_IN_PROGRESS: any;

  currentLogin: any;

  currentError: any;

  activityTimer: any;

  cachedUser: any;

  cachedExp: any;

  stateResolve: any;

  statePromise: any;

  refreshPromise: any;

  decoded: any;

  constructor({ login, logout, pool }: any) {
    this.pool = pool;
    this.userPool = new AmazonCognitoIdentity.CognitoUserPool(pool);
    this.attemptInProgress = false;
    this.loginCB = login;
    this.logoutCB = logout;
    this.resultCBs = [];
    this.savedLocation =
      JSON.parse(
        window.localStorage.getItem('authmgr_last_location') || '[]',
      ) ?? {};

    this.pkce = new PKCE({
      client_id: this.pool.ClientId,
      redirect_uri: `${window.location.origin}/${COGNITO_CB_ENDPOINT}`,
      authorization_endpoint: `${COGNITO_DOMAIN}/oauth2/authorize`,
      token_endpoint: `${COGNITO_DOMAIN}/oauth2/token`,
      requested_scopes: 'email openid profile',
    });
    // this.logout();

    this.attemptAFSCMESSO = this.attemptAFSCMESSO.bind(this);
  }

  get state() {
    return this.currentLogin ? this.currentLogin.state : undefined;
  }

  set state(state) {
    if (state !== this.state) {
      // this._state = state;
      this.currentLogin = {
        ...(this.currentLogin ? this.currentLogin : {}),
        state,
      };
      if (this.stateResolve) {
        const resolve = this.stateResolve;
        this.statePromise = undefined;
        this.stateResolve = undefined;
        resolve(this.current_return_val);
      }
    }
  }

  get current_return_val() {
    return {
      state: this.state,
      login: this.currentLogin,
      error: this.currentError ? this.currentError : null,
    };
  }

  get token_valid() {
    if (
      this.currentLogin &&
      this.currentLogin.id_token &&
      this.currentLogin.access_token
    ) {
      if (!this.cachedExp) {
        const decodedToken = jwtDecode(this.currentLogin.access_token);
        this.decoded = decodedToken;
        if (!decodedToken) {
          return false;
        }
        this.cachedExp = decodedToken.exp ? decodedToken.exp * 1000 : 0;
      }

      const valid = new Date().getTime() < this.cachedExp;
      if (!valid) this.cachedExp = undefined;
      return valid;
    }
    return false;
  }

  get refresh_valid() {
    const refreshToken =
      this.currentLogin && this.currentLogin.refresh_token
        ? this.currentLogin.refresh_token
        : window.localStorage.getItem('refresh_token');
    return (
      refreshToken &&
      refreshToken !== 0 &&
      refreshToken !== null &&
      refreshToken !== undefined &&
      refreshToken !== ''
    );
  }

  stateChange() {
    if (this.statePromise) {
      return this.statePromise;
    }

    this.statePromise = new Promise((resolve, reject) => {
      this.stateResolve = resolve;
    });

    return this.statePromise;
  }

  saveLocation() {
    this.savedLocation = {
      l: new URL(window.location.toString()),
      s: { ...window.history.state },
    };

    window.localStorage.setItem(
      'authmgr_last_location',
      JSON.stringify(this.savedLocation),
    );
    // console.warn("SAVED TO", this.saved_location?.l);
  }

  restoreLocation() {
    console.warn('RESTORING TO', this.savedLocation?.l);

    if (this.savedLocation) {
      window.history.pushState(
        this.savedLocation?.s,
        '',
        this.savedLocation?.l,
      );
    }
  }

  getCognitoUser(id: any): any {
    if (!id) {
      return undefined;
    }
    if (this.cachedUser && this.cachedUser.id === id) {
      return this.cachedUser.cognitoUser;
    }

    const userData = { Username: id, Pool: this.userPool };
    this.cachedUser = {
      id,
      cognitoUser: new AmazonCognitoIdentity.CognitoUser(userData),
    };

    return this.cachedUser.cognitoUser;
  }

  // eslint-disable-next-line class-methods-use-this
  async logout() {
    console.log('logout');
    this.saveLocation();
    const cognitoUser = this.getCognitoUser(
      this.currentLogin ? this.currentLogin.user : null,
    );
    if (cognitoUser) cognitoUser.signOut();
    if (this.activityTimer) this.activityTimer.cancel();
    this.activityTimer = undefined;
    this.state = LOGGED_OUT_STATE;
    this.currentLogin = {};
    window.localStorage.getItem('refresh_token');
    window.localStorage.removeItem('current_user');
    window.localStorage.removeItem('current_name');
    window.localStorage.removeItem('current_roles');
    if (this.logoutCB) this.logoutCB();
  }

  handleCognitoLoginFailed(message: any) {
    console.warn('AUTHMGR: cognito login failed', message);
    this.currentError = message;
    this.state = LOGGED_OUT_STATE;
    // this.logout();
    this.resultCBs.forEach((cb: any) => cb(this.current_return_val));
    this.resultCBs = [];
  }

  // eslint-disable-next-line class-methods-use-this
  handleCognitoLoginSuccess(result: any, credential: any) {
    const idToken = result.getIdToken().getJwtToken();
    const accessToken = result.getAccessToken().getJwtToken();
    const refreshToken = result.getRefreshToken().getToken();
    const { email, name } = this.doLogin({
      id_token: idToken,
      access_token: accessToken,
      refresh_token: refreshToken,
    });

    if (
      (window.navigator.credentials as any).PasswordCredential &&
      credential &&
      credential.password
    ) {
      const c = new (window.navigator.credentials as any).PasswordCredential({
        id: email,
        name,
        password: credential.password,
      });
      const res = navigator.credentials.store(c);
    }
    this.currentError = undefined;
    this.resultCBs.forEach((cb: any) => cb(this.current_return_val));
    this.resultCBs = [];
  }

  async refresh() {
    console.log(
      'this.currentLogin.access_token refresh---------------',
      this.currentLogin.access_token,
    );
    // console.log('this.token_valid---------------', this.token_valid);
    if (this.token_valid) {
      // return this.currentLogin.id_token;
      return {
        id_token: this.currentLogin.id_token,
        access_token: this.currentLogin.access_token,
      };
    }
    if (
      !this.currentLogin ||
      !this.currentLogin.user ||
      !this.currentLogin.refresh_token
    ) {
      return undefined;
    }
    if (this.attemptInProgress) {
      console.warn('AUTHMGR.refresh(): Attempt already in progress');
      return this.refreshPromise;
    }
    this.attemptInProgress = true;
    this.saveLocation();
    this.currentError = undefined;
    this.refreshPromise = new Promise((resolve, reject) => {
      if (this.refresh_valid) {
        const cognitoUser = this.getCognitoUser(
          this.currentLogin ? this.currentLogin.user : null,
        );
        const reftoken =
          this.currentLogin && this.currentLogin.refresh_token
            ? this.currentLogin.refresh_token
            : window.localStorage.getItem('refresh_token');

        const refreshToken = { getToken: () => reftoken };
        cognitoUser.refreshSession(refreshToken, (err: any, res: any) => {
          if (err) {
            this.attemptInProgress = false;
            this.refreshPromise = null;
            this.handleCognitoLoginFailed(err);
            reject(err);
          } else if (res) {
            this.handleCognitoLoginSuccess(res, cognitoUser);
            this.attemptInProgress = false;
            this.refreshPromise = null;
            resolve({
              id_token: this.currentLogin.id_token,
              access_token: this.currentLogin.access_token,
            });
          }
        });
      } else {
        this.refreshPromise = null;
        this.attemptInProgress = false;
        this.handleCognitoLoginFailed('refresh token invalid/expired');
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('refresh token invalid');
      }
    });

    console.log(
      'this.currentLogin.access_token refresh2222---------------',
      this.currentLogin.access_token,
    );
    return this.refreshPromise;
  }

  async attemptAFSCMESSO() {
    this.saveLocation();

    if (DO_PKCE) {
      const authorize = this.pkce.authorizeUrl({
        identity_provider: COGNITO_IDENTITY_PROVIDER,
      });

      window.location.replace(authorize);
    } else {
      // implicit token flow:
      const endpoint = `${COGNITO_DOMAIN}/oauth2/authorize`;
      const params = {
        identity_provider: COGNITO_IDENTITY_PROVIDER,
        redirect_uri: `${window.location.origin}/${COGNITO_CB_ENDPOINT}`,
        response_type: 'TOKEN',
        client_id: this.pool.ClientId,
        scope: 'email openid profile',
      };

      const uri = `${endpoint}?${Object.entries(params)
        .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
        .join('&')}`;
      window.location.replace(uri);
    }
  }

  doLogin({
    id_token: idToken,
    access_token: accessToken,
    refresh_token: refreshToken,
  }: any) {
    const id: any = jwtDecode(idToken);
    const access = accessToken ? jwtDecode(accessToken) : null;

    const idTokenExpires = id.exp;
    const idTokenTime = id.iat;
    const timeout = idTokenExpires - idTokenTime;
    const { email } = id;
    const roles = id['cognito:groups'];
    const username = id['cognito:username'];
    const name = id.given_name ?? id.name ?? email?.split?.('@')?.[0];

    console.log(
      'this.currentLogin.access_token doLogin---------------',
      accessToken,
    );
    const login = {
      expire_secs: timeout,
      expiration: id.exp,
      id_token: idToken,
      refresh_token: refreshToken,
      user: email,
      email,
      name,
      roles,
      access_token: accessToken,
    };

    this.currentLogin = login;
    window.localStorage.setItem('refresh_token', login.refresh_token);
    window.localStorage.setItem('current_user', login.user);
    window.localStorage.setItem('current_name', login.name);
    window.localStorage.setItem('current_roles', JSON.stringify(login.roles));
    if (this.activityTimer) this.activityTimer.cancel();
    this.activityTimer = activityTimer(() => {
      this.activityTimer.cancel();
      this.activityTimer = undefined;
      this.logout();
    });
    this.state = LOGGED_IN_STATE;
    // this.restoreLocation();
    return login;
  }

  async AFSCME_SSO_finish(params: any) {
    try {
      if (params.code) {
        // complete PKCE code sign in
        const url = window.location.href;
        const tokens = await this.pkce.exchangeForAccessToken(url);
        this.doLogin(tokens);
      } else {
        // implicit token
        this.doLogin(params);
      }

      // this.currentLogin.id_token = await this.refresh();
      const { id_token: idToken, access_token: accessToken } =
        await this.refresh();
      this.currentLogin.id_token = idToken;
      this.currentLogin.access_token = accessToken;

      this.state = this.token_valid ? LOGGED_IN_STATE : LOGGED_OUT_STATE;
    } catch (e) {
      this.currentError = e;
      await this.logout();
    }

    return this.current_return_val;
  }

  async completeSSO(location: any) {
    if (this.SSO_IN_PROGRESS) {
      return this.SSO_IN_PROGRESS;
    }
    if (
      !this.SSO_IN_PROGRESS &&
      location.pathname === `/${COGNITO_CB_ENDPOINT}`
    ) {
      const hashParams =
        location.hash !== ''
          ? Object.fromEntries(
              location.hash
                ?.slice(1)
                ?.split('&')
                ?.map((p: any) => p.split('=')),
            )
          : {};
      const searchParams =
        location.search !== ''
          ? Object.fromEntries(
              location.search
                ?.slice(1)
                ?.split('&')
                ?.map((p: any) => p.split('=')),
            )
          : {};
      this.SSO_IN_PROGRESS = this.AFSCME_SSO_finish({
        ...hashParams,
        ...searchParams,
      });
      const result = await this.SSO_IN_PROGRESS;
      this.SSO_IN_PROGRESS = undefined;
      return result;
    }

    return this.current_return_val;
  }

  async rehydrate() {
    this.saveLocation();
    console.log(
      'AUTHMGR.rehydrate()',
      window.localStorage.getItem('current_user'),
    );

    if (!this.currentLogin || !this.currentLogin.refresh_token) {
      this.currentLogin = {
        ...(this.currentLogin ?? {}),
        refresh_token: window.localStorage.getItem('refresh_token'),
        user: window.localStorage.getItem('current_user'),
        name: window.localStorage.getItem('current_name'),
        roles: JSON.parse(window.localStorage.getItem('current_roles') || '[]'),
      };
    }
    if (!this.currentLogin || !this.currentLogin.user) {
      this.state = 'LOGGED OUT';
      return this.current_return_val;
    }
    // attempt to get a new session using a saved refresh_token
    if (
      this.currentLogin.refresh_token &&
      /* don't both if we have a good id_token: */
      (!this.currentLogin.expiration ||
        this.currentLogin.expiration < new Date().valueOf() / 1000)
    ) {
      // this.current_login.id_token = await this.refresh();
      const { id_token: idToken, access_token: accessToken } =
        await this.refresh();
      this.currentLogin.id_token = idToken;
      this.currentLogin.access_token = accessToken;
    }
    this.state = this.token_valid ? 'LOGGED IN' : 'LOGGED OUT';
    return this.current_return_val;
  }
}
