import React from 'react';
import { Routes, Route } from 'react-router-dom';

import HomeRoute from 'routes/Home';
import LoginRoute from 'routes/Login';
import AzureSSOCB from 'routes/Login/AzureSSOCB';
import NotFoundRoute from 'routes/NotFound';

import Layout from 'components/Layout';
import ProtectedRoute from 'components/ProtectedRoute';
import UnProtectedRoute from 'components/UnProtectedRoute';

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route element={<Layout />}>
          <Route path="/" element={<HomeRoute />} />
          <Route path="*" element={<NotFoundRoute />} />
        </Route>
      </Route>

      <Route element={<UnProtectedRoute />}>
        <Route path="/login" element={<LoginRoute />} />
        <Route path="/azure_sso_cb" element={<AzureSSOCB />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
