import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const Container = styled(Box)`
  width: 100%;
  min-height: 100vh;
  padding: 10px;
  padding-top: 60px;
`;

export default Container;
