import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

import { useAFSCMESSOContext } from 'contexts/AFSCMESSOContext';
import { restoreLocation } from 'lib/routing';

const UnProtectedRoute = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAFSCMESSOContext();

  useEffect(() => {
    if (isLoggedIn) {
      restoreLocation(navigate);
    }
  }, [isLoggedIn]);

  return <Outlet />;
};

export default UnProtectedRoute;
