import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
  getIsLoggedIn,
  getTokens,
  updateAuthStatus,
  logout as logoutAction,
} from 'store/slices/sessionSlice';
import { LOGGED_IN_STATE, CognitoAuthMgr } from 'lib/OAuth/CognitoAuthManager';
import { isWhiteList } from 'lib/whitelistCheck';

const authManager = new CognitoAuthMgr({
  login: () => {},
  logout: () => {},
  pool: {
    UserPoolId: 'us-east-1_MTRdKPLjM',
    ClientId: '5ep2h7sjgtm8ieuqa0m90g6gb4',
  },
});

interface IAFSCMESSOContextValue {
  authManager: CognitoAuthMgr;
  inProgress: boolean;
  isLoggedIn: boolean;

  idToken?: string;
  accessToken?: string;
  refreshToken?: string;
  expiration?: any;
  expireSecs?: any;

  // eslint-disable-next-line no-undef
  user?: AFSCME.IUser;

  setInProgress: (state: boolean) => void;
  updateSSOStatus: (payload: any) => void;
  logout: () => void;
}

const defaultAFSCMESSOContextValue: IAFSCMESSOContextValue = {
  authManager,
  inProgress: false,
  isLoggedIn: true,

  setInProgress: (state: boolean) => {},
  updateSSOStatus: (payload: any) => {},
  logout: () => {},
};

export const AFSCMESSOContext = createContext(defaultAFSCMESSOContextValue);
export const useAFSCMESSOContext = () => useContext(AFSCMESSOContext);

interface IAFSCMESSOContextProviderProps {
  children: React.ReactNode;
}

export const AFSCMESSOContextProvider = ({
  children,
}: IAFSCMESSOContextProviderProps) => {
  const dispatch = useAppDispatch();

  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const tokens = useAppSelector(getTokens);

  const [inProgress, setInProgress] = useState<boolean>(true);

  const updateSSOStatus = (payload: any) => {
    dispatch(updateAuthStatus(payload));
  };

  const logout = () => {
    dispatch(logoutAction());
  };

  const attemptSSORehydrate = async () => {
    try {
      const { state, login, error } = await authManager.rehydrate();
      if (error || state !== LOGGED_IN_STATE) {
        throw new Error('Failed to rehydrate');
      }

      const { user, email, name, roles, expiration } = login;

      // Removing temporarily to bypass whiteList check until table is populated again - Martin 9/5
      // const isWhiteListed = await isWhiteList(email, login.access_token);

      updateSSOStatus({
        isLoggedIn: true,
        // isWhiteListed,
        user,
        email,
        name,
        roles,

        idToken: login.id_token,
        accessToken: login.access_token,
        refreshToken: login.refresh_token,
        expiration,
        expireSecs: login.expire_secs,
      });
    } catch (e) {
      logout();
    }
  };

  const bootstrap = async () => {
    if (isLoggedIn) {
      setInProgress(true);
      await attemptSSORehydrate();
      setInProgress(false);
    }
  };

  useEffect(() => {
    bootstrap();
  }, []);

  const contextValue = useMemo(() => {
    return {
      authManager,
      inProgress,
      isLoggedIn,
      ...tokens,

      setInProgress,
      updateSSOStatus,
      logout,
    };
  }, [inProgress, isLoggedIn, tokens]);

  return (
    <AFSCMESSOContext.Provider value={contextValue}>
      {children}
    </AFSCMESSOContext.Provider>
  );
};
