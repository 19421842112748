const LAST_WINDOW_LOCATION_KEY = 'afscme_last_location';

export const saveLocation = () => {
  console.log(`Save location: ${window.location.toString()}`);

  const location = {
    l: window.location.pathname,
    s: { ...window.history.state },
  };

  window.localStorage.setItem(
    LAST_WINDOW_LOCATION_KEY,
    JSON.stringify(location),
  );
};

export const restoreLocation = (navigate: any): number => {
  try {
    const lastLocation = window.localStorage.getItem(LAST_WINDOW_LOCATION_KEY);
    if (lastLocation) {
      const location = JSON.parse(lastLocation);

      console.log(`Restore location: ${location.l}`);

      navigate(location.l, {
        state: location.s,
      });
    } else {
      console.log(`Restore location: Move to home by default`);

      navigate('/');
    }

    return 1;
  } catch {
    return -1;
  }
};
